<script>
import {computed, defineComponent, onMounted, ref} from "vue";
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";
import {useRoute} from "vue-router/composables";
import I18n from "@/plugins/i18n";

export default defineComponent({
  setup() {

    const route = useRoute();
    const dfgId = computed(() => route.params.dfgId);

    const isLoading = ref(true);
    const item = ref({});

    onMounted(() => {
      CLIENT.go(byApi('dfg_survey_entries/' + dfgId.value + '/admin')).get().then(result => {
        item.value = result.asData();


      }).finally(() => {
        isLoading.value = false;
      })
    })

    const subjectGroup = computed(() => {
      const g = item.value.subjectGroup;
      return I18n.t('module.survey.step1.study.area.items').find((e) => e.value === g);
    });

    const subjectArea = computed(() => {
      return subjectGroup?.value?.fields?.find((f) => f.value === item.value.subjectArea);
    });

    function financeTypeString(id) {
      let map = [
        'ohne Verbundfinanzierung',
        '1 bis 49% verbund-finanziert',
        '50% oder mehr verbund-finanziert',
      ];

      return map[parseInt(id)];
    }

    function ortString(id) {
      let map = [
          'keine Angabe',
          'außerhalb Deutschlands',
          'in Deutschland'
      ];

      return map[parseInt(id)];
    }

    function standPromotionString(id) {
      let map = [
          'Ongoing doctoral work, dissertation not (yet) submitted',
          'Dissertation submitted, oral examination pending',
          'Dissertation submitted and oral examination or oral defence (“Disputation”, “Rigorosum”, etc.) passed',
          'Doctoral work discontinued',
          'I don’t know / not specified'
      ]

      return map[parseInt(id)];
    }
    return {isLoading, item, subjectGroup, subjectArea, financeTypeString, ortString, standPromotionString }
  }
});
</script>

<template>
  <div class="pa-2">
    <v-card v-if="!isLoading">
      <div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Name</td>
              <td>{{ item.firstname }} {{ item.lastname }}</td>
            </tr>
            <tr>
              <td>Geburtsjahr</td>
              <td>{{ item.birthyear }}</td>
            </tr>
            <tr>
              <td>Geschlecht</td>
              <td>{{ item.gender }}</td>
            </tr>
            <tr>
              <td>Nationalität</td>
              <td>{{ item.nationality }}</td>
            </tr>
            <tr>
              <td>Fächergruppe</td>
              <td>{{ subjectGroup.label }}</td>
            </tr>
            <tr>
              <td>Fachrichtung</td>
              <td>{{ subjectArea.label }}</td>
            </tr>
            <tr>
              <td>Land vor Eintritt</td>
              <td>{{ item.countryBeforeEnter }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th>Statusgruppe</th>
              <th>Finanzierung</th>
              <th>Beginn</th>
              <th>Ende</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="finance of item.finances">
              <td>{{ finance.statusGroup }}</td>
              <td>{{ financeTypeString(finance.type) }}</td>
              <td>{{ finance.startDate }}</td>
              <td>{{ finance.endDate }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <h4>Primäre Forschungsstelle</h4>
        <v-simple-table>
          <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Ort</td>
            <td>{{ item.place }}</td>
          </tr>
          <tr>
            <td>Institution</td>
            <td>{{ item.institution }}</td>
          </tr>
          </tbody>
        </v-simple-table>

        <h4>Status specific</h4>
        <div v-if="item.statusGroup === '0'">
          <v-simple-table>
            <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>qualifizierender Hochschulabschluss</td>
              <td>{{ item.qualifiesDoctorate === '1' ? 'I have a master or similar degree - e.g. state examination, diploma' : 'I have been accepted to the doctoral programme as a graduate of a bachelor programme (Fast Track)' }}</td>
            </tr>
            <tr>
              <td>Zeitpunkt Hochschulabschluss</td>
              <td>{{ item.universityDegreeDateQualifiesDoctorate === '1' ? 'keine Angabe' : item.universityDegreeDateQualifiesDoctorateDate }}</td>
            </tr>
            <tr>
              <td>Ort Antwort</td>
              <td>{{ ortString(item.lastAcademicPlaceAnswer) }}</td>
            </tr>
            <tr>
              <td>Ort</td>
              <td>{{ item.lastAcademicPlace }}</td>
            </tr>
            <tr>
              <td>Ort Institution</td>
              <td>{{ item.lastAcademicPlaceInstitution }}</td>
            </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table>
            <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Beginn Promotion</td>
              <td>{{ item.promotionBeginAnswer === '1' ? 'keine Angabe' : item.promotionBeginDate }}</td>
            </tr>
            <tr>
              <td>Stand Promotion</td>
              <td>{{ standPromotionString(item.doctoralProjectStatus) }}</td>-
            </tr>
            <tr>
              <td>Dissertation</td>
              <td>{{ item.dissertationFinishAnswer === '1' ? 'keine Angabe' : item.dissertationFinishDate }}</td>
            </tr>
            <tr>
              <td>Oral Examination</td>
              <td>{{ item.examAnswerNew === '1' ? 'keine Angabe' : item.examDate }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>

        {{ item }}
      </div>
    </v-card>
  </div>
</template>

<style scoped>

</style>